<template>
    <faq-list :boardInfo="boardInfo"
                 :boardList="boardList"
                 :noticeList="noticeList"
                 :boardCount="boardCount"
                 :codeList="codeList"
                 :size="size"
                 :page="page" @changePage="aa"></faq-list>
</template>

<script>
    import FaqList from "@/components/boards/lists/listFaq";
    export default {
        name: "faq_list",
        components: {FaqList},
        data(){
            return {
                boardList  : [],
                noticeList: [],
                codeList: [],
                boardInfo: {},
                boardCount: 0,
                page: 0,
                count: 0,
                code: '',
                size: 8,
            }
        },
        mounted() {
            this.getBoardList();
        },
        methods:{

            getBoardList() {

                this.$eventBus.$emit("startProgress")

                // this.params.mode = "list";
                var temp = JSON.parse(JSON.stringify(this.$route.params))
                // var temp = JSON.parse(JSON.stringify(this.params))
                temp.size = this.size || 8
                temp.page = this.page || 0
                temp.cateCode = this.code
                temp.boardKey = 'cms_faq'
                //

                var self = this
                this.axiosCaller.get(this, this.APIs.BOARDS + "/getBoardList", temp, (res) => {
                    var result = res.data
                    console.log(result, "====>")
                    if (result.status === "ok") {
                        if (result.boardInfoVO.isNologin && !this.isLogin()) {
                            this.requireLogin(this, false)
                        }
                        this.boardCount = result.boardMap.count
                        //this.boardList.push(...result.boardMap.list)
                        this.boardList = result.boardMap.list
                        this.noticeList = result.noticeMap.list
                        this.boardInfo = result.boardInfoVO

                        let to = localStorage.getItem("to")
                        let prevIdx = localStorage.getItem("prevIdx")
                        if (to) {
                            this.$nextTick(() => {window.scroll(0, to)})
                            localStorage.removeItem("to")
                        }
                        if (prevIdx) {
                            this.$nextTick(() => {
                                this.webUtils.scrollTo("#item-" + prevIdx)
                            })
                            localStorage.removeItem("prevIdx")
                        }

                        self.axiosCaller.get(this, this.APIs.COMMON_CODE + "/getCodeTree", {
                            code: this.boardInfo.cateCode
                        }, (res) => {
                            var result = res.data
                            console.log(result, "====>")
                            if (result.status === "ok") {
                                console.log(result)
                                self.codeList = result.codeList
                            } else {
                                // this.swalUtils.gritter("Error", result.msg, "error")
                            }
                            this.$eventBus.$emit("doneProgress")
                        })

                    } else {
                        this.swalUtils.gritter("Error", result.msg, "error")
                    }
                    this.$eventBus.$emit("doneProgress")
                })
            },
            aa(data){
                this.page = data.page
                this.code = data.code
                // console.log(page)

                this.getBoardList();
            }

        }
    }
</script>

<style scoped>

</style>